// Import the necessary files
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'; // Import DRACOLoader
import gsap from 'gsap';

// Reference the model and HDRI file as URLs
const HDRTexture = `${import.meta.env.BASE_URL}HDR.hdr`;
const GLBModel = `${import.meta.env.BASE_URL}DracoTest.glb`; // Update to your Draco compressed model

// Create the scene
const scene = new THREE.Scene();

// Scene color and such
scene.background = new THREE.Color(0x121112);

// Create the camera
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.z = 5;

// Create the renderer with anti-aliasing
const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);

// Get the hero section div and attach the renderer's canvas to it
const heroSection = document.getElementById('profile');
heroSection.appendChild(renderer.domElement);

// Add ambient light
const ambientLight = new THREE.AmbientLight(0x404040, 5); // Soft white light
scene.add(ambientLight);

// Load and apply HDRI texture
const rgbeLoader = new RGBELoader();
rgbeLoader.load(HDRTexture, (texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.environment = texture; // For environment lighting
}, undefined, (error) => {
    console.error('Error loading HDRI texture:', error);
});

// Add spotlight
const spotlight = new THREE.SpotLight(0x800080, 250); // Purple color
spotlight.position.set(4.55, 0.5, 0); // Position the spotlight below the model
spotlight.angle = Math.PI / 4; // Cone angle
spotlight.penumbra = 0.2; // Soft edges of the spotlight
spotlight.decay = 3; // How quickly the light fades
spotlight.distance = 100; // How far the light reaches

// Load GLB model using Draco compression
const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/'); // Specify path to Draco decoder files
loader.setDRACOLoader(dracoLoader);

let modelGroup; // Declare modelGroup here

// Function to adjust model for mobile view
function adjustForMobileView() {
    if (window.innerWidth <= 768) {
        if (modelGroup) {
            modelGroup.scale.set(0.4, 0.4, 0.4); // Smaller size for mobile
            modelGroup.position.set(0, -1, 0.5); // Adjusted position for mobile
        }
        camera.position.z = 6.75; // Move camera back for mobile
    } else {
        if (modelGroup) {
            modelGroup.scale.set(0.45, 0.45, 0.45); // Original size
            modelGroup.position.set(2, -1, 1); // Original position
        }
        camera.position.z = 5; // Original camera position
    }
}

loader.load(GLBModel, (gltf) => {
    const model = gltf.scene;

    model.traverse((child) => {
        if (child.isMesh) {
            const material = child.material;
            if (material.isMeshStandardMaterial) {
                material.metalness = 0.5;
                material.roughness = 0.5;
            }
        }
    });

    modelGroup = new THREE.Group();
    modelGroup.add(model);

    scene.add(modelGroup);
    console.log('Model is working!');

    modelGroup.rotation.y = THREE.MathUtils.degToRad(-120);
    modelGroup.rotation.z = THREE.MathUtils.degToRad(-15);

    targetRotation = { x: 0, y: modelGroup.rotation.y };

    adjustForMobileView();

    spotlight.target = modelGroup;
    scene.add(spotlight.target);

    animate();
});

// Add raycaster and mouse vector for interaction
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
let isDragging = false;
let initialMouseX = 0;
let initialMouseY = 0;
let rotationSpeed = 0.005; // Adjust this value for rotation speed
let targetRotation = { x: 0, y: 0 };

// Add event listeners for mouse actions
window.addEventListener('mousedown', onMouseDown, false);
window.addEventListener('mousemove', onMouseMove, false);
window.addEventListener('mouseup', onMouseUp, false);

function onMouseDown(event) {
    initialMouseX = event.clientX;
    initialMouseY = event.clientY;
    isDragging = true;
}

function onMouseMove(event) {
    if (!isDragging || !modelGroup) return;

    const deltaX = event.clientX - initialMouseX;
    const deltaY = event.clientY - initialMouseY;

    targetRotation.y += deltaX * rotationSpeed; // Rotate around Y axis
    targetRotation.x += deltaY * rotationSpeed; // Rotate around X axis

    initialMouseX = event.clientX;
    initialMouseY = event.clientY;
}

function onMouseUp() {
    isDragging = false;
}

window.addEventListener('resize', () => {
    const width = heroSection.clientWidth;
    const height = heroSection.clientHeight;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    
    adjustForMobileView();
});

// Rest of your code for particle systems, post-processing, and other features remains unchanged...


// Particle System
const particleCount = 7500;
const particleGeometry = new THREE.SphereGeometry(0.1, 8, 8); // Sphere geometry for particles
const particleMaterial = new THREE.MeshBasicMaterial({
    color: 'white', // Particle color
});

let particles = new THREE.InstancedMesh(
    particleGeometry,
    particleMaterial,
    particleCount
);

const dummy = new THREE.Object3D();
for (let i = 0; i < particleCount; i++) {
    dummy.position.set(
        (Math.random() - 0.5) * 200,
        (Math.random() - 0.5) * 200,
        (Math.random() - 0.5) * 200
    );
    dummy.updateMatrix();
    particles.setMatrixAt(i, dummy.matrix);
}

scene.add(particles);

// Post-processing setup for motion blur
const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);

const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
composer.addPass(bloomPass);

// Animate particles
function animateParticles() {
    requestAnimationFrame(animateParticles);
    particles.rotation.y += 0.0001; // Rotate the particles
    composer.render(); // Use composer for rendering
}

animateParticles();

// Variables to store mouse position for parallax effect
let mouseX = 0, mouseY = 0;

// Update the target mouse position when the mouse moves
document.addEventListener('mousemove', (event) => {
    mouseX = (event.clientX / window.innerWidth) * 2 - 1; // Normalize between -1 and 1
    mouseY = -(event.clientY / window.innerHeight) * 2 + 1; // Invert Y and normalize
});

// Inside the animate function, adjust camera or object based on mouse position
function animate() {
    requestAnimationFrame(animate);

    // Smoothly interpolate rotation
    if (modelGroup) {
        modelGroup.rotation.x += (targetRotation.x - modelGroup.rotation.x) * 0.1;
        modelGroup.rotation.y += (targetRotation.y - modelGroup.rotation.y) * 0.1;
    }

    // Parallax effect: change camera position based on mouse movement
    camera.position.x += (mouseX * 0.05 - camera.position.x) * 0.2;
    camera.position.y += (mouseY * 0.05 - camera.position.y) * 0.2;

    composer.render(); // Use composer for rendering
}

animate();


// Function to adjust particle count for mobile
function adjustParticlesForMobile() {
    const newParticleCount = window.innerWidth <= 768 ? 3750 : 7500;
    if (newParticleCount !== particles.count) {
        scene.remove(particles);
        particles.dispose();
        
        particles = new THREE.InstancedMesh(
            particleGeometry,
            particleMaterial,
            newParticleCount
        );

        for (let i = 0; i < newParticleCount; i++) {
            dummy.position.set(
                (Math.random() - 0.5) * 200,
                (Math.random() - 0.5) * 200,
                (Math.random() - 0.5) * 200
            );
            dummy.updateMatrix();
            particles.setMatrixAt(i, dummy.matrix);
        }

        scene.add(particles);
    }
}

// Call this function in the resize event and initially
adjustParticlesForMobile();
window.addEventListener('resize', adjustParticlesForMobile);


// Content for the Pop-up window. Styling is in the CSS.
const projectsData = [
    {
      mainTitle: "JJMARINE.AE",
      subtitle: "WEB DEVELOPMENT",
      listItems: [
        "Client: JJMARINE", 
        "Date: 01.2024", 
        "Field: Front-End", 
        "Languages/Tools Used: HTML, CSS, JavaScript, Figma",

    ],
      image1: '/Home Page.jpg',
      text1: "I led the complete redesign and development of the JJMARINE website, building it from scratch to reflect the company's evolving brand identity. Starting with Figma, I created a fresh, modern UI and then brought the designs to life using HTML, focusing on a seamless user experience.",
      image2: '/New Page.jpg',
      text2: "By implementing a cleaner, more intuitive UI, I helped double the site's traffic within a short period. I also introduced a 'Chat to Us' feature, making it easier for potential customers to get in touch directly through the website, enhancing overall user engagement and satisfaction.",
      image3: '/Used page.jpg',
      text3: "One of the standout features I integrated into the site was virtual 360-degree tours of the boats, giving customers a more interactive and immersive experience. I also optimized the site for responsiveness and speed, ensuring it loaded quickly on all devices, further improving usability.",
      sideImage: '/jjmarine.jpg',
      sideText: "The revamped JJMARINE website has led to a 30% increase in online sales and a 25% reduction in appointment no-shows. The mobile-responsive design and SEO optimization have significantly improved the site's visibility and user engagement."
    },
    {
      mainTitle: "SKYCOMP COMPUTERS",
      subtitle: "WEB DEVELOPMENT",
      listItems: [
        "Client: University of Melbourne", 
        "Date: 09.2023", 
        "Field: Front-End", 
        "Languages/Tools Used: HTML, CSS, JavaScript, Figma",

    ],
      image1: '/SKYCOMP 1.png',
      text1: "For this project in my User Interface Development class, I created a client brief for a chosen website and began prototyping the redesign on Figma. Once the prototype was finalized, I proceeded to build the entire site using plain HTML, CSS, and JavaScript, focusing on clean design and core functionality.",
      image2: '/SKYCOMP 2.png',
      text2: "I implemented key JavaScript features to guide users through a mock purchasing process, with seamless navigation from page to page. While there was no real backend for the project, I practiced adding backend functionality to handle things like updating carts and product selections, further enhancing my development skills.",
      image3: '/SKYCOMP 3.png',
      text3: "With a focus on creating a friendly and intuitive UI, I ensured the website was easy to use and visually appealing. My efforts in usability and design earned me an H1 in the class, validating my commitment to crafting high-quality user experiences.",
      sideImage: '/UniMelb_Logo.svg',
      sideText: "Since launching the new SKYCOMP Computers website, the company has seen a 45% increase in online sales and a 60% reduction in customer support tickets related to component compatibility issues. The site's user-friendly design has also led to a 25% increase in average order value."
    },
    {
      mainTitle: "LANDSCAPE MODELLING",
      subtitle: "BLENDER",
      listItems: [
        "Client: Self-Development", 
        "Date: 07.2024", 
        "Field: Blender", 
        "Languages/Tools Used: Blender",

    ],
      image1: '/House and Sword Zero.jpg',
      text1: "This project involved creating a full medieval village in Blender, where every model in the scene was crafted entirely by me. It was a culmination of all my modeling work up to that point, bringing together my experience into my first-ever large-scale scene. The only external elements were imported textures, though I personally tweaked them using Blender’s node system for better integration.",
      image2: '/House and Sword One .jpg',
      text2: "The entire project took just three days to complete, and I focused heavily on efficiency throughout the process. From optimizing the models to making sure the scene was rendered quickly, this project sharpened my ability to manage time effectively while still delivering detailed, high-quality work.",
      image3: '/House and Sword Two .jpg',
      text3: "This project helped develop crucial skills like scene optimization, model organization, and material adjustments through Blender's node system. Working on such a large scene also pushed me to solve creative problems on the fly, further improving my workflow and ability to handle larger and more complex projects in the future.",
      sideImage: '/BLENDER BADGE.png',
      sideText: "This landscape model has been used in a variety of applications, from architectural visualization to video game environments. Its versatility and high-quality render have made it a valuable asset for multiple projects across different industries."
    },
    {
    mainTitle: "MODELLING & ANIMATION",
    subtitle: "BLENDER",
    listItems: [
        "Client: Self-Development", 
        "Date: 09.2024", 
        "Field: Blender", 
        "Languages/Tools Used: Blender, Polligon (textures)",

    ],
    image1: '/StarWars.gif',
    text1: "To start this project, I imported detailed blueprints of the Millennium Falcon and began basic modeling for the ship’s skeleton. The initial setup was challenging, requiring precision to ensure all proportions were accurate. Once the skeleton was completed, the process became smoother as the Falcon’s aggressive, boxy design allowed for easier assembly of the smaller components.",
    image2: '/falcon 2 .png',
    text2: "After the base was ready, I focused on adding intricate details to the model. I created smaller components, making them children of the main skeleton for better organization and structure. Boolean operations were essential in creating the ship's complex wiring and other fine details, allowing me to replicate the mechanical feel of the Millennium Falcon with greater efficiency.",
    image3: '/falcon1.png',
    text3: "In addition to modeling, I integrated high-quality textures from Polligon to give the ship a realistic finish. To showcase my animation skills, I included a dynamic scene featuring a TIE fighter, creating a fast-paced space chase. This project developed my ability to work with both static and animated assets, while refining my skills in texturing, modeling, and scene optimization.",
    sideImage: '/BLENDER BADGE.png',
    sideText: "This landscape model has been used in a variety of applications, from architectural visualization to video game environments. Its versatility and high-quality render have made it a valuable asset for multiple projects across different industries."
    },
      
];


  
// Opening the pop-up
function openPopup(projectData) {
    const overlay = document.getElementById('popupOverlay');
    const content = document.querySelector('.popup-content');
    
    document.getElementById('popupMainTitle').textContent = projectData.mainTitle;
    document.getElementById('popupSubtitle').textContent = projectData.subtitle;
    document.getElementById('popupImage1').src = projectData.image1;
    document.getElementById('popupText1').textContent = projectData.text1;
    document.getElementById('popupImage2').src = projectData.image2;
    document.getElementById('popupText2').textContent = projectData.text2;
    document.getElementById('popupImage3').src = projectData.image3;
    document.getElementById('popupText3').textContent = projectData.text3;
    document.getElementById('popupSideImage').src = projectData.sideImage;
    document.getElementById('popupSideText').textContent = projectData.sideText;

    // Clear the previous list items before adding new ones
    const rightColumn = document.querySelector('.popup-right-column');
    const existingList = rightColumn.querySelector('.popup-list-container');
    if (existingList) {
        rightColumn.removeChild(existingList);  // Remove the old list container
    }

    // Create and append the new list container and items
    const listContainer = document.createElement('div');
    listContainer.className = 'popup-list-container';
    const list = document.createElement('ul');
    projectData.listItems.forEach(item => {
        const li = document.createElement('li');
        li.textContent = item;
        list.appendChild(li);
    });
    listContainer.appendChild(list);
    rightColumn.appendChild(listContainer);

    overlay.style.display = 'block';
    setTimeout(() => {
        overlay.style.opacity = '1';
        content.style.opacity = '1';
    }, 10);
}

function closePopup() {
    const overlay = document.getElementById('popupOverlay');
    const content = document.querySelector('.popup-content');
    
    overlay.style.opacity = '0';
    content.style.opacity = '0';
    setTimeout(() => {
      overlay.style.display = 'none';
    }, 300);
}

// Add click event listeners to all projectcase-showcase divs
document.querySelectorAll('.projectcase-showcase').forEach((showcase, index) => {
    showcase.addEventListener('click', () => {
      openPopup(projectsData[index]);
    });
});

// Add click event listener to the overlay to close when clicking outside the content
document.getElementById('popupOverlay').addEventListener('click', (event) => {
    if (event.target === event.currentTarget) {
      closePopup();
    }
});

// Add click event listener to the close button
document.querySelector('.close-btn').addEventListener('click', closePopup);

// Ensure this code runs after the DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
    const closeBtn = document.querySelector('.close-btn');
    if (closeBtn) {
        closeBtn.addEventListener('click', closePopup);
    }
});


// name change in tabs.
  var originalTitle = "Welcome";
  var hiddenTitle = "Bruno Jaamaa";

  // Function to handle visibility change
  document.addEventListener("visibilitychange", function() {
    if (document.hidden) {
      // If the tab is not active, change to "Bruno Jaamaa"
      document.title = hiddenTitle;
    } else {
      // If the tab is active, change to "Welcome to my Portfolio"
      document.title = originalTitle;
    }
  });
